import React from 'react';
import type { ReactNode } from 'react';
import Template from '@clearscore/shared.website-template';
import Spacer from '@clearscore/ui.rainbow.spacer';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

import Hero from './components/hero';
import OurVision from './components/our-vision';
import Leadership from './components/leadership';
import Investors from './components/investors';
import styles from './about-us.module.css';

interface ISectionProps {
    children: ReactNode;
    cols?: string;
}

const Section = ({ children, cols }: ISectionProps) => (
    <Spacer
        all={{ vertical: Spacer.spacings.BIG }}
        medium={{ vertical: Spacer.spacings.SUPER }}
        data-layout={`content ${cols || ``}`}
    >
        {children}
    </Spacer>
);

const FullBleedSection = ({ children, cols }: ISectionProps) => (
    <div data-layout="full-bleed" className={styles.bg}>
        <Template.Grid>
            <Section cols={cols}>{children}</Section>
        </Template.Grid>
    </div>
);

interface IAboutUsPageProps {
    appStoreConfig: {
        googlePlayUrl?: string;
        appStoreUrl?: string;
        appStoreLink?: string;
        playStoreLink?: string;
        appleStoreToggle?: boolean;
        googleStoreToggle?: boolean;
    };
    cookiePolicyConfig: {
        moreInfoLinkUrl: string;
        enabled: boolean;
        language: Record<string, string>;
    };
    leaderImages: Record<string, IGatsbyImageData>;
    investorImages: Record<string, IGatsbyImageData>;
}

const AboutUsPage = ({ appStoreConfig, cookiePolicyConfig, leaderImages, investorImages }: IAboutUsPageProps) => (
    <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
        <article className={styles.aboutUs} data-layout="full-bleed">
            <Template.Grid>
                <Section cols="cols-8@large">
                    <Hero />
                </Section>
                <FullBleedSection cols="cols-8@large">
                    <OurVision />
                </FullBleedSection>
                <Section>
                    <Leadership images={leaderImages} />
                </Section>
                <Section>
                    <Investors images={investorImages} />
                </Section>
            </Template.Grid>
        </article>
    </Template>
);

export default AboutUsPage;
