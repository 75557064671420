import { GB, ZA, AU, CA, NZ } from '@clearscore/config.i18n';

import socialMediaImageGb from '../assets/gb/avatar_gb_250x250.jpg';
import socialMediaImageAu from '../assets/au/avatar_au_250x250.jpg';
import socialMediaImageZa from '../assets/za/avatar_za_250x250.jpg';
import socialMediaImageNz from '../assets/nz/avatar_nz_250x250.jpg';
import socialMediaImageCareers from '../assets/global/avatar_careers_420x420.jpg';

export default {
    homepage: {
        [CA]: {
            socialMediaImage: socialMediaImageGb,
            organisationSchema: {
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                name: 'ClearScore',
                url: 'https://www.clearscore.com/ca',
                logo: '',
                sameAs: [
                    'https://www.facebook.com/clearscore',
                    'https://en.wikipedia.org/wiki/ClearScore',
                    'https://twitter.com/ClearScore',
                ],
            },
        },
        [ZA]: {
            socialMediaImage: socialMediaImageZa,
            organisationSchema: {
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                name: 'ClearScore South Africa',
                url: 'https://www.clearscore.com/za',
                logo: '',
                sameAs: [
                    'https://www.facebook.com/ClearScore-SA-1863993373838683',
                    'https://en.wikipedia.org/wiki/ClearScore',
                    'https://twitter.com/ClearScore_sa',
                ],
            },
        },
        [AU]: {
            socialMediaImage: socialMediaImageAu,
            organisationSchema: {
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                name: 'ClearScore Australia',
                url: 'https://www.clearscore.com/au',
                logo: '',
                sameAs: [
                    'https://www.facebook.com/clearscoreau/',
                    'https://en.wikipedia.org/wiki/ClearScore',
                    'https://twitter.com/ClearScoreAU',
                ],
            },
        },
        [GB]: {
            socialMediaImage: socialMediaImageGb,
            organisationSchema: {
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                name: 'ClearScore',
                url: 'https://www.clearscore.com',
                logo: '',
                sameAs: [
                    'https://www.facebook.com/clearscore',
                    'https://en.wikipedia.org/wiki/ClearScore',
                    'https://twitter.com/ClearScore',
                ],
            },
        },
        [NZ]: {
            socialMediaImage: socialMediaImageNz,
            organisationSchema: {
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                name: 'ClearScore New Zealand',
                url: 'https://www.clearscore.com/nz',
                logo: '',
                sameAs: [
                    'https://www.facebook.com/clearscoreau/',
                    'https://en.wikipedia.org/wiki/ClearScore',
                    'https://twitter.com/ClearScoreAU',
                ],
            },
        },
    },
    careers: {
        [ZA]: {
            socialMediaImage: socialMediaImageCareers,
        },
        [AU]: {
            socialMediaImage: socialMediaImageCareers,
        },
        [GB]: {
            socialMediaImage: socialMediaImageCareers,
        },
        [NZ]: {
            socialMediaImage: socialMediaImageCareers,
        },
    },
    loansPage: {
        [GB]: {
            schemaMarkup: [
                {
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: [
                        {
                            '@type': 'Question',
                            name: 'Can you apply for a loan with a bad credit score?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'If you have a bad or low credit score, lenders may offer you a higher interest rate or a secured loan, which can be a better option. With ClearScore, you can see your eligibility for loans based on your score. Learn more about <a href=’https://www.clearscore.com/learn/loans/how-to-get-a-loan-with-a-low-credit-score’>getting a loan with a bad credit score.</a>',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'How much could you borrow?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'The amount of money you can borrow depends on a few things like your income, expenses and credit score. That’s why it’s important to compare loans before you apply for one.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Can you pay off your loan early?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'Yes, you can, but check if there are Early Repayment Charges (ERCs) before you do.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Can you take out a loan if you’re on universal credit?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'Whilst you can’t take out a regular loan, you can make a request for a ‘budgeting advance’ if you’re on universal credit. The advance must be used for certain costs (like work-related expenses or repairing a broken fridge) and you’d receive reduced universal credit until you’ve paid off the amount you borrowed.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'What should you do if you’re struggling to repay your loan?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'Whatever the reason is, if you’re struggling to make your loan repayments you should contact your lender, or reach out to charities like <a href=’https://www.citizensadvice.org.uk/debt-and-money/borrowing-money/types-of-borrowing/loans/’>Citizens Advice.</a>',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'What is a soft and hard credit check?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'A soft search (or ‘check’) is when a lender assesses your eligibility for a product. Only you can see the soft searches on your credit report, and they have no impact on your credit score. A hard search happens when you apply for a loan or credit and will be shown on your credit report. It’s common for a hard search to affect your credit score but, as long as you borrow responsibly, the impact should be short-term.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'What is APR?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'APR stands for annual percentage rate. When you take out a loan, you’ll need to pay it back ‘with interest’. That’s where APR comes in – it’s made up of the interest rate and other charges you might have to pay (like an annual fee). Lenders sometimes offer a different APRs and you should make sure you understand how it impacts the total amount you’ll pay back.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Does checking loan offers harm my credit score?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'No – checking your loan offers on ClearScore will never harm your credit score. You can compare your personalised loans in your Offers panel as often as you like, without affecting your score.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Does ClearScore have exclusive offers on loans?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'From time to time, we work with some of the UK’s top lenders to bring you exclusive offers. We show these as ‘Exclusive’ in the Offers panel, so you’ll know which ones they are.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Which partners does ClearScore work with?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'We work with over 45 lenders like Admiral, Natwest, 118 118 Money, Halifax and more.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'How does ClearScore work?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'When you sign up to ClearScore you can see your credit score and credit report for free. We work with lenders to offer our users market-leading deals on credit products. We may receive a commission if you take out a product with a lender. This article will explain how we make money. <a href=’https://help.clearscore.com/hc/en-us/articles/360019194599-How-does-ClearScore-make-money-’>This article</a> will explain how we make money.',
                            },
                        },
                    ],
                },
                {
                    '@context': 'http://schema.org',
                    '@type': 'WebPage',
                    headline: 'Compare loans without harming your score',
                    publisher: {
                        '@type': 'Organization',
                        name: 'ClearScore',
                    },
                    keywords: 'compare loans',
                    author: {
                        '@type': 'Organization',
                        name: 'ClearScore',
                        url: 'https://www.clearscore.com',
                    },
                    URL: 'https://www.clearscore.com/loans',
                    about: [
                        {
                            '@type': 'Thing',
                            name: 'Loans',
                            sameas: 'https://en.wikipedia.org/wiki/Loan',
                        },
                    ],
                    mentions: [
                        {
                            '@type': 'Thing',
                            name: 'Debt Consolidation Loan',
                            sameas: 'https://en.wikipedia.org/wiki/Debt_consolidation',
                        },
                        {
                            '@type': 'Thing',
                            name: 'Unsecured Personal Loan',
                            sameas: 'https://en.wikipedia.org/wiki/Loan#Unsecured',
                        },
                        {
                            '@type': 'Thing',
                            name: 'Secured Loan',
                            sameas: 'https://en.wikipedia.org/wiki/Loan_guarantee',
                        },
                        {
                            '@type': 'Thing',
                            name: 'Guarantor Loan',
                            sameas: 'https://en.wikipedia.org/wiki/Unsecured_guarantor_loan',
                        },
                        {
                            '@type': 'Thing',
                            name: 'Car Finance',
                            sameas: 'https://en.wikipedia.org/wiki/Car_finance',
                        },
                        {
                            '@type': 'Thing',
                            name: 'Credit Score',
                            sameas: 'https://en.wikipedia.org/wiki/Credit_score',
                        },
                    ],
                },
            ],
        },
    },
    creditScoresPage: {
        [AU]: {
            schemaMarkup: [
                {
                    '@context': 'http://schema.org',
                    '@type': 'WebPage',
                    headline: 'Check your credit score for free.',
                    publisher: {
                        '@type': 'Organization',
                        name: 'ClearScore',
                    },
                    Keywords: 'credit score',
                    author: {
                        '@type': 'Organization',
                        name: 'ClearScore',
                        url: 'https://www.clearscore.com',
                    },
                    URL: 'https://www.clearscore.com/au/credit-scores',
                    about: [
                        {
                            '@type': 'Thing',
                            name: 'Credit Score',
                            sameas: 'https://en.wikipedia.org/wiki/Credit_score',
                        },
                    ],
                    mentions: [
                        {
                            '@type': 'Thing',
                            name: 'Credit Report',
                            sameas: 'https://en.wikipedia.org/wiki/Credit_history',
                        },
                    ],
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: [
                        {
                            '@type': 'Question',
                            name: ' How does ClearScore use your data?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: "We keep your data safe and we’ll never sell your information to any third parties. All information you give to us is stored on our secure servers. You can find more information in our <a href='https://www.clearscore.com/au/privacy-policy'>privacy policy</a>.",
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Does checking your credit report affect your credit score?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: "No – you can <a href='https://www.clearscore.com/au/learn/credit-score-and-report/does-a-credit-check-affect-your-credit-score'>check your score and report as many times as you like</a> without harming your score.",
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'How can you check your credit score and report?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'You’ll see your credit score as soon as you log in, and your report is right there too. Why not download our app – so everything you need is in the palm of your hand. You can also log in on a desktop or laptop if you prefer.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'What’s the best way to improve your credit score?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: "We update your insights weekly and tell you if you’re doing well, or if there’s room to improve. So you can be sure you’re on track and improving your credit score. Have a look at our <a href='https://www.clearscore.com/au/learn/credit-score-and-report/how-to-improve-your-credit-score'>12 easy ways to improve your score</a>.",
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'How often is your credit score updated?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: 'We partner with Experian – one of main credit reporting bodies in Australia – and we get your credit report and score once a month from them. We’ll let you know when your report is ready and your score’s changed.',
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'What affects your credit score?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: "Lots of things affect your credit score. For example, <a href='https://www.clearscore.com/au/learn/credit-cards/how-can-using-a-credit-card-affect-your-credit-score'>applying for too much credit</a> over a short period of time (like taking out a loan and credit card within a few months of each other), or missing your repayments. Keeping an eye on your report can help you understand what makes your score move.",
                            },
                        },
                        {
                            '@type': 'Question',
                            name: 'Why is ClearScore’s credit score different to other providers?',
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: "We get your credit report and financial information from Experian and use that information to give you your score. Credit scores can be slightly different between the different providers – you can find out more about <a href='https://www.clearscore.com/au/learn/credit-score-and-report/whats-the-difference-between-clearscore-and-experian'>how we work</a> here.",
                            },
                        },
                    ],
                },
            ],
        },
    },
};
