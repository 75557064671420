import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';
import Spacer from '@clearscore/ui.rainbow.spacer';

import SectionHeader from '../section-header';
import { PAGE_NAMESPACE } from '../../lib/constants';

const OurVision = () => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return (
        <section data-qa="our-vision">
            <SectionHeader>{t('ourVision.heading')}</SectionHeader>
            <Spacer all={{ bottom: Spacer.spacings.MEDIUM }}>
                <Text.Body1>{t('ourVision.blurb.helpAccessCreditScore')}</Text.Body1>
            </Spacer>
            <Text.Body1>{t('ourVision.blurb.helpControlFinancialFuture')}</Text.Body1>
        </section>
    );
};

export default OurVision;
