import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import Template from '@clearscore/shared.website-template';
import Spacer from '@clearscore/ui.rainbow.spacer';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import SectionHeader from '../section-header';
import styles from './leadership.module.css';
import * as showStyles from '../../show.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

interface IBadgeProps {
    name: string;
    jobTitle: string;
    blurb: string;
    image: IGatsbyImageData | null;
    dataLayout?: string | null;
}

const transformCopy = (text: string) =>
    interpolateWithComponents(text, [
        {
            Component: Text.Link,
        },
    ]) as React.ReactNode[];

const Badge = ({ name, jobTitle, blurb, image = null, dataLayout = null }: IBadgeProps) => (
    <Spacer
        all={{ bottom: Spacer.spacings.BIG }}
        medium={{ bottom: Spacer.spacings.HUGE, right: Spacer.spacings.HUGE }}
        data-layout={dataLayout}
    >
        <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
            <div className={styles.image}>{image && <GatsbyImage image={image} alt="" />}</div>
        </Spacer>
        <Spacer medium={{ bottom: Spacer.spacings.MICRO }}>
            <Text.H3>{name}</Text.H3>
        </Spacer>
        <Spacer medium={{ bottom: Spacer.spacings.MEDIUM }}>
            <Text.H4 tag={Text.tags.P}>{transformCopy(jobTitle)}</Text.H4>
        </Spacer>
        <div className={showStyles['show-medium']}>
            <Text.Body1>{transformCopy(blurb)}</Text.Body1>
        </div>
    </Spacer>
);

const getLeadership = (images: ILeadershipProps['images'], language: Record<string, string>) => [
    { name: 'Justin Basini', jobTitle: language.gceo, blurb: language.gceo_blurb, image: images.gceo },
    { name: 'Brian Cole', jobTitle: language.gcfo, blurb: language.gcfo_blurb, image: images.gcfo },
    { name: 'Louise Jones', jobTitle: language.vpp, blurb: language.vpp_blurb, image: images.vpp },
    { name: 'Andy Sleigh', jobTitle: language.ceouk, blurb: language.ceouk_blurb, image: images.ceouk },
    { name: 'Michael Woodburn', jobTitle: language.cdo, blurb: language.cdo_blurb, image: images.cdo },
    { name: 'Klaus Thorup', jobTitle: language.cto, blurb: language.cto_blurb, image: images.cto },
    { name: 'Mark Reid', jobTitle: language.ca, blurb: language.ca_blurb, image: images.ca },
    { name: 'Yolanda Martin', jobTitle: language.vpd, blurb: language.vpd_blurb, image: images.vpd },
    { name: 'Tom Markham', jobTitle: language.vpc, blurb: language.vpc_blurb, image: images.vpc },
    { name: 'Andrew Hooks', jobTitle: language.vpcars, blurb: language.vpcars_blurb, image: images.vpcars },
    { name: 'Vera Mehta', jobTitle: language.sdoe, blurb: language.sdoe_blurb, image: images.sdoe },
    { name: 'Tim Kelleway', jobTitle: language.dodone, blurb: language.dodone_blurb, image: images.dodone },
    { name: 'Sara Sheridan', jobTitle: language.dogir, blurb: language.dogir_blurb, image: images.dogir },
    { name: 'Vicki Fraser', jobTitle: language.ukdop, blurb: language.ukdop_blurb, image: images.ukdop },
    { name: 'Elisabeth Forseth', jobTitle: language.ukcrmd, blurb: language.ukcrmd_blurb, image: images.ukcrmd },
    { name: 'Neil Barton', jobTitle: language.ukhoe, blurb: language.ukhoe_blurb, image: images.ukhoe },
    { name: 'Tassie Milne', jobTitle: language.gmca, blurb: language.gmca_blurb, image: images.gmca },
    { name: 'Peter McLoughlin', jobTitle: language.gmza, blurb: language.gmza_blurb, image: images.gmza },
    { name: 'Lloyd King', jobTitle: language.hofpa, blurb: language.hofpa_blurb, image: images.hofpa },
    { name: 'Maz Boulos', jobTitle: language.hop, blurb: language.hop_blurb, image: images.hop },
];

interface ILeadershipProps {
    images: Record<string, IGatsbyImageData>;
}

const Leadership = ({ images }: ILeadershipProps) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const dataLayout = 'cols-2 cols-4@medium';
    const leadership = getLeadership(images, t('leadership'));
    return (
        <section data-testid="leadership" className={styles.leadership}>
            <SectionHeader>{t('leadership.heading')}</SectionHeader>

            <Template.Grid isContentGrid>
                {leadership.map((person) => (
                    <Badge key={person.name} {...person} dataLayout={dataLayout} />
                ))}
            </Template.Grid>
        </section>
    );
};

export default Leadership;
