import React from 'react';
import uid from '@clearscore-group/lib.helpers.uid';
// Match and extract values for 3 groups. For <0>foo bar</0> it will match:
// 1. Opening brackets for component - <0>
// 2. Component attributes - href="example.com" id="my-id"
// 3. Children component - foo bar
// 4. Closing brackets for component - </0>
const ELEMENT_MATCH_REGEX = /<(\d)(.*?)>(.*?)<\/(\d)>/;

// Match whole group with closing brackets e.g. <0>foo bar</0>
const GROUP_MATCH_REGEX = /(<\d.*?>.*?<\/\d>)/;

// Match attributes passed in via interpolation to use as props
const ATTRIBUTE_MATCH_REGEX = /(\w.+?)="(.+?)"/g;

const rawAttributesToMap = (rawAttributes = '') => {
    const attributeCount = rawAttributes.match(ATTRIBUTE_MATCH_REGEX)?.length || 0;
    const attributeMap = {};

    for (let index = 0; index < attributeCount; index += 1) {
        const [, key, value] = ATTRIBUTE_MATCH_REGEX.exec(rawAttributes);
        attributeMap[key] = value;
    }

    return attributeMap;
};
/**
 * Given a string parsers xml tags and maps indexes to components array values
 * to render appropriate component.
 *
 * @param {string} lang content which might contain special tags
 * @param {{ Component: import('react').ComponentType<any>, props: object }[]} components array of components and
 * props to pass if they are rendered
 *
 * @returns {import('react').ReactNodeArray} array of renderable elements
 */
const interpolateString = (lang, components) => {
    const interpolations = lang.match(GROUP_MATCH_REGEX);
    if (!interpolations) return lang;
    return lang.split(GROUP_MATCH_REGEX).map((str) => {
        const componentMatch = str.match(ELEMENT_MATCH_REGEX);
        if (!componentMatch) return str;

        const [, ComponentOpeningIndex, rawAttributes, children, ComponentClosingIndex] = componentMatch;
        const componentIndex = ComponentOpeningIndex === ComponentClosingIndex ? ComponentOpeningIndex : null;
        const attributes = rawAttributesToMap(rawAttributes || '');

        if (!componentIndex) return str;

        const { Component, props = {} } = components[componentIndex];

        return React.createElement(Component, { ...attributes, ...props, key: props.key || uid() }, children);
    });
};

export default interpolateString;
