import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';
import type { ReactNode } from 'react';

const SectionHeader: React.FC = ({ children }: { children: ReactNode }) => (
    <Spacer all={{ bottom: Spacer.spacings.MEDIUM }} medium={{ bottom: Spacer.spacings.LARGE }}>
        <Text.H2>{children}</Text.H2>
    </Spacer>
);
export default SectionHeader;
