import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';
import Spacer from '@clearscore/ui.rainbow.spacer';

import { PAGE_NAMESPACE } from '../../lib/constants';

const Hero = () => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return (
        <section data-qa="hero">
            <Spacer all={{ bottom: Spacer.spacings.TINY }} medium={{ bottom: Spacer.spacings.LARGE }}>
                <Text.H1>{t('hero.heading')}</Text.H1>
            </Spacer>
            <Spacer all={{ bottom: Spacer.spacings.MEDIUM }}>
                <Text.Body1>{t('hero.financialMarketplace')}</Text.Body1>
            </Spacer>
            <Text.Body1>{t('hero.globalUsers')}</Text.Body1>
        </section>
    );
};

export default Hero;
