import type { ReactElement } from 'react';
import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';

import AboutUs from '../components/verticals/about-us';
import marketConfig from '../lib/market-config';
import Layout from '../components/layout';

const normaliseImages = (
    prev: Record<string, unknown>,
    { name, childImageSharp }: { name: string; childImageSharp: { gatsbyImageData: unknown } },
): Record<string, unknown> => ({
    ...prev,
    [name]: childImageSharp.gatsbyImageData,
});

const AboutUsTemplate = ({
    data,
    pageContext: { market },
    location,
}: PageProps<Queries.AboutUsImagesQuery, { market?: string }>): React.ReactElement => {
    const profileImages = data.profileImages.nodes.reduce(normaliseImages, {});
    const investorImages = data.investorImages.nodes.reduce(normaliseImages, {});
    const { socialMediaImage } = marketConfig.homepage[market];

    return (
        <Layout
            meta={{
                title: `About Us | Our Vision and Our People | ClearScore ${market.toUpperCase()}`,
                description: '',
                image: socialMediaImage,
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <AboutUs
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    // @ts-expect-error fix after merge
                    leaderImages={profileImages}
                    // @ts-expect-error fix after merge
                    investorImages={investorImages}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query AboutUsImages {
        profileImages: allFile(filter: { absolutePath: { regex: "/global/leaders/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(quality: 80, layout: FULL_WIDTH)
                }
            }
        }
        investorImages: allFile(filter: { absolutePath: { regex: "/about-us/investors/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(quality: 80, layout: FULL_WIDTH)
                }
            }
        }
    }
`;

export default AboutUsTemplate;
