import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import Template from '@clearscore/shared.website-template';
import Spacer from '@clearscore/ui.rainbow.spacer';

import SectionHeader from '../section-header';
import styles from './investors.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

interface IInvestorsProps {
    images: Record<string, IGatsbyImageData>;
}

const Investors = ({ images }: IInvestorsProps) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return (
        <section data-qa="investors" className={styles.investors}>
            <Template.Grid isContentGrid>
                <div data-layout="cols-4 cols-4@medium cols-6@large">
                    <SectionHeader>{t('investors.heading')}</SectionHeader>
                </div>
                <ul className={styles.imageContainer} data-layout="cols-4 cols-4@medium cols-6@large">
                    <li>
                        <GatsbyImage
                            image={{ ...images.invus, layout: 'constrained', width: 100, height: 120 }}
                            alt="Invus"
                        />
                    </li>
                    <li>
                        <Spacer all={{ right: Spacer.spacings.MEDIUM }} large={{ right: Spacer.spacings.LARGE }}>
                            <GatsbyImage image={images.blenheim_chalcot} alt="Blenheim Chalcot" />
                        </Spacer>
                    </li>
                    <li>
                        <Spacer all={{ right: Spacer.spacings.MEDIUM }} large={{ right: Spacer.spacings.LARGE }}>
                            <GatsbyImage image={images.qed} alt="QED Investors" />
                        </Spacer>
                    </li>
                    <li>
                        <GatsbyImage image={images.lead_edge} alt="Lead Edge" />
                    </li>
                </ul>
            </Template.Grid>
        </section>
    );
};

export default Investors;
